// title: Podcast Annotation Project
// frameCardName: Scrivener
// frameCardSet: ODY
// lastUpdatedDate: 2022-05-16
// noIndex: true

import * as styles from './index.module.scss'

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props {
  pageContext: any
}

const AnnotationProjectPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#469CA4"
      includeNewsletterSignup
    >
      <article className={styles.container}>
        <h1 className={styles.heading}>
          <Typography.SecondaryHeading>
            The Great Lucky Paper Radio Retroactive Annotation Project
          </Typography.SecondaryHeading>
        </h1>
        <Typography.Paragraph>
          This project is now complete! Every episode down to the very first now
          has annotated timestamps and a dedicated page listing every card
          mentioned in throughout the episode.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Big thanks to our listeners for helping with old episodes:
        </Typography.Paragraph>

        <Typography.UnorderedList>
          <li>
            <a href="https://cubecobra.com/cube/list/tahlo">Tahlo</a>
          </li>
          <li>
            <a href="http://cubecobra.com/cube/overview/balbud">
              donald_k_magic
            </a>
          </li>
          <li>
            <a href="https://cubecobra.com/cube/overview/611aefae18212505668943c2">
              Muenzer
            </a>
          </li>
        </Typography.UnorderedList>
      </article>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Lucky Paper Radio Podcast Annotation Project',
        description:
          'Want to help support the show? Help us improve the podcast by annotating an old episode.',
      }}
    />
  )
}

export default AnnotationProjectPage
